<template>
  <div v-if="widget.outer == 'core/banner'" class="mx-auto max-w-6xl">
    <page-builder-banner class="mx-6 my-2" :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/3_image_links'" :class="props.containedClass">
    <page-builder-3-image-links :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/cake_header'" :class="props.containedClass">
    <page-builder-cake-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/about_block'" :class="props.containedClass">
    <page-builder-about-block :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/recipe_list'" :class="props.containedClass">
    <page-builder-recipe-list :page="props.urlParts.url_parameter_2" :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/stockist_list'" :class="props.containedClass">
    <page-builder-stockist-list :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/recipe'" :class="props.containedClass">
    <page-builder-recipe :url="props.urlParts.item_url" :templatevar="widget.templatevar" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-6xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
