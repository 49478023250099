<template>
  <div>
    <div v-if="recipe.name">
      <cake-header class="print:text-center">
        Cake cream - {{ recipe.name }} Recipe
      </cake-header>
      <div class="mx-auto flex max-w-2xl flex-col justify-between pt-4 text-secondary-500 lg:max-w-none lg:flex-row">
        <div class="mx-auto pb-4 lg:order-last lg:mx-0 print:flex">
          <cgn-lazy-image class="w-72 pb-2" :image="recipe.image" />
          <div class="print:order-first print:p-2">
            <div v-if="recipe.difficulty" class="flex items-center gap-1">
              Difficulty:
              <i-heroicons-solid:cake v-for="index in recipe.difficulty" :key="index" class="h-4" />
            </div>
            <div v-if="recipe.servings">
              Serves: {{ recipe.servings }}
            </div>
            <div v-if="recipe.prep_time">
              Prep time: {{ recipe.prep_time }} min
            </div>
            <div v-if="recipe.cook_time">
              Cook time: {{ recipe.cook_time }} min
            </div>
            <div v-if="recipe.prep_time && recipe.cook_time">
              Total time: {{ recipe.prep_time + recipe.cook_time }} min
            </div>
          </div>
        </div>
        <div class="prose max-w-3xl text-secondary-500 prose-p:my-1 prose-p:text-secondary-500 prose-a:text-brand-600 prose-a:no-underline hover:prose-a:text-brand-700 prose-strong:text-secondary-500 prose-ul:m-0 prose-li:my-0 print:prose-p:my-0">
          <div v-if="recipe.description" class="pb-2" v-html="recipe.description" />

          <div v-if="recipe.author" class="pb-2">
            <span class="text-2xl font-bold text-brand-500">Author</span>
            <div>
              <p>{{ recipe.author }}</p>
            </div>
          </div>

          <div v-if="recipe.yield" class="pb-2">
            <span class="text-2xl font-bold text-brand-500">Yield</span>
            <div>
              <p>{{ recipe.yield }}</p>
            </div>
          </div>

          <div v-if="recipe.ingredients" class="pb-2">
            <span class="text-2xl font-bold text-brand-500">Ingredients</span>
            <div class="pb-2" v-html="recipe.ingredients" />
          </div>

          <div v-if="recipe.method" class="pb-2">
            <span class="text-2xl font-bold text-brand-500">Method</span>
            <div v-html="recipe.method" />
          </div>

          <div v-if="recipe.hints" class="pb-2">
            <span class="text-2xl font-bold text-brand-500">Hints</span>
            <div v-html="recipe.hints" />
          </div>
        </div>
      </div>
      <cgn-button class="print:hidden" color-brand @click="printPage()">
        <i-heroicons-solid:printer class="inline" /> Print
      </cgn-button>
      <div class="print:hidden">
        <div v-for="document in recipe.documents" :key="document.url">
          <cgn-button color-brand :url="config.baseURL + document.url">
            {{ document.name }}
          </cgn-button>
        </div>
      </div>
      <div v-if="recipe.related_recipes.length > 0" class="print:hidden">
        <span class="pb-2 text-2xl font-bold text-brand-500">Other recipes</span>
        <div class="grid gap-6 sm:grid-cols-2 md:grid-cols-3">
          <router-link v-for="other_recipe in recipe.related_recipes" :key="other_recipe.id" :to="`/recipe/${other_recipe.url}`" class="block text-xl uppercase text-secondary-500">
            <cgn-lazy-image :image="other_recipe.image" class="mb-2 w-full" />
            {{ other_recipe.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { FwcmsRecipe } from '~cognito/models/Fwcms/Recipe'
import { config } from '~/config'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  url: {
    type: String,
    default: '',
  },
})
const printPage = () => {
  window.print()
}

const recipe = ref<FwcmsRecipe>(new FwcmsRecipe())
const loadRecipe = async (url: string) => {
  recipe.value = new FwcmsRecipe()
  if (url.length == 0) {
    return
  }
  const data = await new FwcmsRecipe().find_one({
    url,
    image_aspect: '1x1',
    image_width: 300,
  })
  recipe.value = data
}

watch(() => props.url, (newUrl) => {
  loadRecipe(newUrl)
})
onMounted(() => {
  loadRecipe(props.url)
})
onServerPrefetch(async () => {
  await loadRecipe(props.url)
})
// TODO print button
</script>
