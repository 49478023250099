<template>
  <Swiper
    :slides-per-view="1"
    :autoplay="{
      delay: 4000,
      disableOnInteraction: false,
    }"
    :modules="swiperModules"
  >
    <SwiperSlide v-for="banner in banners" :key="banner.name">
      <a :href="banner.link">
        <cgn-lazy-image :image="banner.primary_image" class="w-full" :class="{ 'hidden md:block': banner?.secondary_image }" />
        <cgn-lazy-image v-if="banner?.secondary_image" :image="banner.secondary_image" class="w-full md:hidden" />
      </a>
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import { FwcmsBanner } from '~cognito/models/Fwcms/Banner'
import 'swiper/css'

class Templatevars {
  type?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const swiperModules = ref([Autoplay])

const banners = ref<FwcmsBanner[]>([])
const loadBanner = async () => {
  const data = await new FwcmsBanner().find_many({
    image_width: 1920,
    page_size: 50,
  })
  banners.value = data.data
}
onMounted(() => {
  loadBanner()
})
onServerPrefetch(async () => {
  await loadBanner()
})
</script>
