import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://cakecream.${config.devbase}`
config.prodURL = 'https://cms.cakecream.com.au'
config.siteURL = 'https://cakecream.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.sentryDsn = 'https://c4fb10cc909b4a3dbe437f550a0a3af1@glitchtip.logger.jm1.au/7'

export { config }
