<template>
  <div>
    <div>
      <cake-header v-if="props.templatevar.heading">
        {{ props.templatevar.heading }}
      </cake-header>
      <div class="prose prose-lg pb-4" v-html="templatevar.content" />
    </div>
    <div v-for="state in stockistStates" :key="state">
      <cake-header :large-heading="false" :secondary-header="true" :thin-line="true">
        {{ state }}
      </cake-header>
      <div class="grid gap-6 pb-6 md:grid-cols-2 lg:grid-cols-3">
        <div v-for="stockist in getStockistsOfState(state)" :key="stockist.id" class="flex flex-col justify-between text-xl text-secondary-500">
          <div class="flex flex-col">
            <span class="font-bold uppercase">{{ stockist.name }}</span>
            <span>{{ stockist.address_line_1 }}</span>
            <span>{{ stockist.address_line_2 }}</span>
            <span>{{ stockist.suburb }} {{ stockist.state }} {{ stockist.postcode }}</span>
            <a :href="`https://${stockist.website}`" target="_blank" class="text-brand-600 hover:text-brand-700">{{ stockist.website }}</a>
            <span v-if="stockist.email">Email: {{ stockist.email }}</span>
            <span v-if="stockist.phone">Tel: {{ stockist.phone }}</span>
          </div>
          <a :href="stockist.map_url" class="cgn-button block w-full bg-brand-500 text-white hover:bg-brand-600 dark:hover:bg-brand-400" target="_blank">
            Map
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { FwcmsStockist } from '~cognito/models/Fwcms/Stockist'

class Templatevars {
  heading?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const stockistStates = computed(() => {
  const state_List = ref([])
  stockists.value.forEach((stockist) => {
    if (!state_List.value.find(state => state == stockist.state)) {
      state_List.value.push(stockist.state)
    }
  })

  return state_List.value
})

const getStockistsOfState = (state: string) => {
  return stockists.value.filter(stockist => stockist.state == state)
}

const stockists = ref<FwcmsStockist[]>([])
const loadStockists = async () => {
  const data = await new FwcmsStockist().find_many({
    image_aspect: '1x1',
    image_width: 300,
    page_size: 999,
  })
  stockists.value = data.data
}

onMounted(() => {
  loadStockists()
})
onServerPrefetch(async () => {
  await loadStockists()
})
</script>
