<template>
  <div class="flex items-center gap-5 py-2">
    <div v-if="props.centered" class="h-1.5 grow bg-brand-500" />
    <div
      class="shrink font-roboto text-2xl uppercase"
      :class="[
        { 'text-center': props.centered },
        { 'text-3xl font-bold': props.largeHeading },
        { 'text-brand-500': !props.secondaryHeader },
        { 'text-secondary-500': props.secondaryHeader },
      ]"
    >
      <slot />
    </div>
    <div
      v-if="props.showLine" class="grow" :class="[
        { 'h-[1px]': props.thinLine },
        { 'h-1.5': !props.thinLine },
        { 'bg-brand-500': !props.secondaryHeader },
        { 'bg-secondary-500': props.secondaryHeader },
      ]"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  centered: {
    type: Boolean,
    default: false,
  },
  largeHeading: {
    type: Boolean,
    default: true,
  },
  showLine: {
    type: Boolean,
    default: true,
  },
  thinLine: {
    type: Boolean,
    default: false,
  },
  secondaryHeader: {
    type: Boolean,
    default: false,
  },
})
</script>
