<template>
  <div>
    <div>
      <cake-header v-if="templatevar.heading">
        {{ templatevar.heading }}
      </cake-header>
      <div class="prose prose-lg pb-4" v-html="templatevar.content" />
    </div>
    <div class="grid gap-6 sm:grid-cols-2 md:grid-cols-3">
      <router-link v-for="recipe in recipes" :key="recipe.id" :to="`/recipe/${recipe.url}`" class="block text-xl uppercase text-secondary-500">
        <cgn-lazy-image :image="recipe.image" class="mb-2 w-full" />
        {{ recipe.name }}
      </router-link>
    </div>
    <cgn-pagination :current-page="props.page" :page-count="pageCount" url-prefix="recipes" />
  </div>
</template>

<script lang="ts">
import { FwcmsRecipe } from '~cognito/models/Fwcms/Recipe'

class Templatevars {
  heading?: string
  content?: string
  category?: number
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  page: {
    type: Number,
    default: 1,
  },
})

const recipes = ref<FwcmsRecipe[]>([])
const pageCount = ref(0)

const loadRecipes = async () => {
  const data = await new FwcmsRecipe().find_many({
    image_aspect: '1x1',
    image_width: 300,
    page_size: 18,
    page: props.page,
    group: props.templatevar.category,
  })
  recipes.value = data.data
  pageCount.value = data.num_pages
}

watch(() => props.page, () => {
  loadRecipes()
})
onMounted(() => {
  loadRecipes()
})
onServerPrefetch(async () => {
  await loadRecipes()
})
</script>
