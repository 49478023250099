<template>
  <div>
    <cake-header v-if="templatevar.heading" :centered="templatevar.centered === '1'">
      {{ templatevar.heading }}
    </cake-header>
  </div>
</template>

<script lang="ts">
class Templatevars {
  heading?: string
  centered?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
