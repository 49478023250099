<template>
  <div>
    <cake-header v-if="templatevar.heading">
      {{ templatevar.heading }}
    </cake-header>
    <div class="grid gap-2 md:grid-cols-2">
      <cgn-lazy-image class="mx-auto w-72 md:order-last md:w-full" :image="image_url" />
      <div class="prose prose-lg pb-4 text-secondary-500 prose-p:text-secondary-500 prose-a:text-brand-600 prose-a:no-underline hover:prose-a:text-brand-700" v-html="templatevar.content" />
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoImage } from '~cognito/models/Cognito/Image'

class Templatevars {
  heading?: string
  content?: string
  image?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const image_url = ref<CognitoImage>(new CognitoImage())
const loadImage = async () => {
  if (props.templatevar.image) {
    await new CognitoImage().find_one({
      url: props.templatevar.image,
      image_aspect: '1x1',
      image_width: 300,
    })
      .then((data) => {
        image_url.value = data
      })
  }
}
onMounted(() => {
  loadImage()
})
onServerPrefetch(async () => {
  await loadImage()
})
</script>
