<template>
  <div class="text-center">
    <div class>
      <h1 class="my-2 text-2xl font-bold text-gray-800 dark:text-white">
        Page not found
      </h1>
      <p
        class="my-2 text-gray-800 dark:text-white"
      >
        Please visit our homepage to get where you need to go.
      </p>
      <cgn-button color-brand url="/">
        Take me home
      </cgn-button>
    </div>
  </div>
</template>

<route lang="yaml">
name: not-found
meta:
  layout: 404
</route>
