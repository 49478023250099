<template>
  <div class="grid gap-6 text-secondary-500 sm:grid-cols-2 md:grid-cols-3">
    <cgn-link v-if="picture_1.url" :to="templatevar.link_1">
      <cgn-lazy-image :image="picture_1" class="mb-2 w-full" />
      <span class="block text-xl uppercase">{{ templatevar.heading_1 }}</span>
      <div class="text-lg" v-html="templatevar.description_1" />
    </cgn-link>
    <cgn-link v-if="picture_2.url" :to="templatevar.link_2">
      <cgn-lazy-image :image="picture_2" class="mb-2 w-full" />
      <span class="block text-xl uppercase">{{ templatevar.heading_2 }}</span>
      <div class="text-lg" v-html="templatevar.description_2" />
    </cgn-link>
    <cgn-link v-if="picture_3.url" :to="templatevar.link_3">
      <cgn-lazy-image :image="picture_3" class="mb-2 w-full" />
      <span class="block text-xl uppercase">{{ templatevar.heading_3 }}</span>
      <div class="text-lg" v-html="templatevar.description_3" />
    </cgn-link>
  </div>
</template>

<script lang="ts">
import { CognitoImage } from '~cognito/models/Cognito/Image'

class Templatevars {
  image_1?: string
  link_1?: string
  heading_1?: string
  description_1?: string
  image_2?: string
  link_2?: string
  heading_2?: string
  description_2?: string
  image_3?: string
  link_3?: string
  heading_3?: string
  description_3?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const picture_1 = ref<CognitoImage>(new CognitoImage())
const picture_2 = ref<CognitoImage>(new CognitoImage())
const picture_3 = ref<CognitoImage>(new CognitoImage())
const loadImage = async () => {
  if (props.templatevar.image_1) {
    await new CognitoImage().find_one({
      url: props.templatevar.image_1,
      image_aspect: '1x1',
      image_width: 300,
    })
      .then((data) => {
        picture_1.value = data
      })
  }
  if (props.templatevar.image_2) {
    await new CognitoImage().find_one({
      url: props.templatevar.image_2,
      image_aspect: '1x1',
      image_width: 300,
    })
      .then((data) => {
        picture_2.value = data
      })
  }
  if (props.templatevar.image_3) {
    await new CognitoImage().find_one({
      url: props.templatevar.image_3,
      image_aspect: '1x1',
      image_width: 300,
    })
      .then((data) => {
        picture_3.value = data
      })
  }
}
onMounted(() => {
  loadImage()
})
onServerPrefetch(async () => {
  await loadImage()
})
</script>
